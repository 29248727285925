<template>
  <v-card-text>
    <v-data-table
      :headers="headers"
      :items="bookings"
      :hide-default-footer="hideDefaultFooter"
      class="elevation-1"
    >
      <template
        v-slot:[`item.customer`]="{ item }"
      >
        {{ item.customer.firstName }} {{ item.customer.surName }}
      </template>
     
      <template
        v-slot:[`item.createdAt`]="{ item }"
      >
        {{ getReadableDate(item.createdAt) }}
      </template>
      <template
        v-slot:[`item._id`]="{ item }"
      >
        {{ constructId(item._id) }}
      </template>

      <template
        v-slot:[`item.pickup.address`]="{ item }"
      >
      <v-tooltip top>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              {{getShortAddress(item.pickup.address)}} </div>
          </template>
          <span>{{item.pickup.address}}</span>
        </v-tooltip>
      </template>
      <template
        v-slot:[`item.dropoff.address`]="{ item }"
      >
      <v-tooltip top>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              {{getShortAddress(item.dropoff.address)}} </div>
          </template>
          <span>{{item.dropoff.address}}</span>
        </v-tooltip>
      </template>


    </v-data-table>
  </v-card-text>
</template>

<script>
  // import { mapGetters } from 'vuex'
  export default {
    // props: ['hideDefaultFooter', 'maxRows'],
    props: {
      hideDefaultFooter: {
        type: Boolean,
        default: true,
      },
      maxRows: {
        type: Number,
        default: 10,
      },
    },
    data () {
      return {
        page: 1,
        headers: [
          {
            sortable: false,
            text: 'ID',
            value: '_id',
          },
          {
            sortable: false,
            text: 'Customer',
            value: 'customer',
          },
          {
            sortable: false,
            text: 'Pickup Location',
            value: 'pickup.address',
          },
          {
            sortable: false,
            text: 'Dropoff Location',
            value: 'dropoff.address',
          },
          {
            sortable: false,
            text: 'Request Type',
            value: 'requestType',
          },
          // {
          //   sortable: false,
          //   text: 'Price',
          //   value: 'price'
          // },
          {
            sortable: false,
            text: 'Created on',
            value: 'createdAt',
          },
          {
            sortable: false,
            text: 'status',
            value: 'status',
          },
        ],
      }
    },
    computed: {
      // ...mapGetters({
      //   bookings: 'Bookings'
      // }),
      bookings () {
        return this.maxRows
          ? this.$store.getters?.Bookings?.request?.slice(0, this.maxRows)
          : this.$store.getters.Bookings
      },
    },
    mounted () {
      this.$store.dispatch('getAllBookings')
    },
    methods: {
      getReadableDate (date) {
        const d = new Date(date)
        return d.toUTCString()
      // }
      //   return `${d.toString().substring(4, 10)}, ${d
      //     .toString()
      //     .substring(11, 15)}`
      },
       getShortAddress(address) {
      return (address.length > 25? `${address.slice(0, 25)}...` : address)
    },
    constructId(id) {
      return (id?.slice(17, 19) + "-" + id?.slice(19)).toUpperCase()
    },
    },
  }
</script>

<style></style>
