<template>
  <v-container id="dashboard" fluid tag="section">
    <date-filter :init-date="initDate" @dateChange="onDateChange" />

    <v-row v-if="stats" class="mr-2">
      <v-col v-if="chartStats" cols="12">
        <base-material-card color="warning" style="background-color: #f7f7f7">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">Financial Stats</div>
          </template>
          <v-btn
              color="blue darken-1"
              text
              @click="toggleChart"
            >
              change Chart
            </v-btn>
           <vue-apex-charts
            width="100%"
            height="350"
            type="line"
            :options="requestsChart.options"
            :series="requestsChart.series"
          />
          <v-row>
            <v-col cols="3" >
              <v-card
                class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1"
                color="white"
                flat
              >
                <v-list-item>
                  <v-list-item-content class="pa-2">
                    <v-list-item-title class="text-h5 mb-1">
                      £
                      {{ chartStats.payments_info.totalRevenue }}
                      <br />
                      <v-list-item-subtitle>
                        <span class="black--text text-p">Total Revenue </span>
                        <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total amount of of revenue. </span>
                          </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
           <v-col cols="3" >
              <v-card
                class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1"
                color="white"
                flat
              >
                <v-list-item>
                  <v-list-item-content class="pa-2">
                    <v-list-item-title class="text-h5 mb-1">
                      £
                      {{ chartStats.payments_info.totalDiscounts }}
                      <br />
                      <v-list-item-subtitle>
                        <span class="black--text text-p">Total Discount </span>
                        <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total amount of of discounts. </span>
                          </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col cols="3" >
              <v-card
                class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1"
                color="white"
                flat
              >
                <v-list-item>
                  <v-list-item-content class="pa-2">
                    <v-list-item-title class="text-h5 mb-1">
                      £
                      {{ chartStats.payments_info.totalWinchitCommission }}
                      <br />
                      <v-list-item-subtitle>
                        <span class="black--text text-p">Winchit Commission </span>
                        <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total amount of of winchit commission. </span>
                          </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col cols="3" >
              <v-card
                class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1"
                color="white"
                flat
              >
                <v-list-item>
                  <v-list-item-content class="pa-2">
                    <v-list-item-title class="text-h5 mb-1">
                      £
                      {{ chartStats.payments_info.totalDriverCommission }}
                      <br />
                      <v-list-item-subtitle>
                        <span class="black--text text-p">Driver Commission </span>
                        <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total amount of of driver commission. </span>
                          </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col cols="3" >
              <v-card
                class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1"
                color="white"
                flat
              >
                <v-list-item>
                  <v-list-item-content class="pa-2">
                    <v-list-item-title class="text-h5 mb-1">
                      £
                      {{ chartStats.payments_info.totalPayouts }}
                      <br />
                      <v-list-item-subtitle>
                        <span class="black--text text-p">Total Payouts</span>
                        <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total amount of of payouts. </span>
                          </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col cols="3" >
              <v-card
                class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1"
                color="white"
                flat
              >
                <v-list-item>
                  <v-list-item-content class="pa-2">
                    <v-list-item-title class="text-h5 mb-1">
                      £
                      {{ chartStats.payments_info.totalCancelFee }}
                      <br />
                      <v-list-item-subtitle>
                        <span class="black--text text-p">Cancel Fee </span>
                        <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total amount of of cancel fee. </span>
                          </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>

          </v-row>
        </base-material-card>
      </v-col> 
      <v-col cols="6">
        <base-material-card style="background-color: #f7f7f7">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">User Stats</div>
          </template>
            <vue-apex-charts
            width="100%"
            height="350"
            type="line"
            :options="usersChart.options"
            :series="usersChart.series"
          />
          <v-row>
            <v-col cols="6">
              <v-card
                class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1"
                color="white"
                flat
              >
                <v-list-item>
                  <v-list-item-content class="pa-2">
                    <v-list-item-title class="text-h5 mb-1">
                      {{ chartStats.user_counts.total_customer_count }}
                      <br />
                      <v-list-item-subtitle>
                        <span class="black--text">Total Customers</span>
                        <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total number of customers that have joined between the dates selected above </span>
                          </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>

            <v-col cols="6">
              <v-card
                class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1"
                color="white"
                flat
              >
                <v-list-item>
                  <v-list-item-content class="pa-2">
                    <v-list-item-title class="text-h5 mb-1">
                      {{ chartStats.user_counts.total_driver_count }}
                      <br />
                      <v-list-item-subtitle>
                        <span class="black--text">Total Drivers</span>
                        <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total number of drivers that have joined between the dates selected above </span>
                          </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>

            <v-col cols="6">
              <v-card
                class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1"
                color="white"
                flat
              >
                <v-list-item>
                  <v-list-item-content class="pa-2">
                    <v-list-item-title class="text-h5 mb-1">
                      {{ chartStats.user_counts.block_drivers_count }}
                      <br />
                      <v-list-item-subtitle>
                        <span class="black--text">Block Drivers</span>
                        <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total number of block drivers that have joined between the dates selected above </span>
                          </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>

            <v-col cols="6">
              <v-card
                class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1"
                color="white"
                flat
              >
                <v-list-item>
                  <v-list-item-content class="pa-2">
                    <v-list-item-title class="text-h5 mb-1">
                      {{ chartStats.user_counts.active_drivers_count }}
                      <br />
                      <v-list-item-subtitle>
                        <span class="black--text">Active Drivers</span>
                        <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total number of active drivers that have joined between the dates selected above </span>
                          </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>

      <v-col cols="6" class="px-0">
        <base-material-card style="background-color: #f7f7f7">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">Requests</div>
          </template>
           <vue-apex-charts
            width="100%"
            height="350"
            type="pie"
            :options="requestsPieChart.options"
            :series="requestsPieChart.series"
          />
          <v-row style="margin-top:0.005rem">
            <v-col cols="6">
              <v-card
                class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1"
                color="white"
                flat
              >
                <v-list-item>
                  <v-list-item-content class="pa-2">
                    <v-list-item-title class="text-h5 mb-1">
                      {{ chartStats.bookingCounts.activeBookingCount }}
                      <br />
                      <v-list-item-subtitle>
                        <span class="black--text">Pending</span>
                        <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total number of requests that are created but no quoute accespted on these. </span>
                          </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>

            <v-col cols="6">
              <v-card
                class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1"
                color="white"
                flat
              >
                <v-list-item>
                  <v-list-item-content class="pa-2">
                    <v-list-item-title class="text-h5 mb-1">
                      {{ chartStats.bookingCounts.cancelledBookingCount }}
                      <br />
                      <v-list-item-subtitle>
                        <span class="black--text">Cancelled</span>
                        <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total number of requests that are cancelled. </span>
                          </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>

            <v-col cols="6">
              <v-card
                class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1"
                color="white"
                flat
              >
                <v-list-item>
                  <v-list-item-content class="pa-2">
                    <v-list-item-title class="text-h5 mb-1">
                      {{ this.chartStats.bookingCounts.scheduledBookingCount }}
                      <br />
                      <v-list-item-subtitle>
                        <span class="black--text">Scheduled</span>
                        <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total number of requests that are scheduled. </span>
                          </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>

            <v-col cols="6">
              <v-card
                class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1"
                color="white"
                flat
              >
                <v-list-item>
                  <v-list-item-content class="pa-2">
                    <v-list-item-title class="text-h5 mb-1">
                      {{ this.chartStats.bookingCounts.completedBookingCount }}
                      <br />
                      <v-list-item-subtitle>
                        <span class="black--text">Completed</span>
                        <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total number of requests that are completed. </span>
                          </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
      
      <v-col cols="6">
        <base-material-card style="background-color: #f7f7f7">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">Customers Stats</div>
          </template>
           <!-- <vue-apex-charts
            width="100%"
            height="350"
            type="line"
            :options="usersChart.options"
            :series="usersChart.series"
          /> -->
          <v-row>
            <v-col cols="6">
              <v-card
                class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1"
                color="white"
                flat
              >
                <v-list-item>
                  <v-list-item-content class="pa-2">
                    <v-list-item-title class="text-h5 mb-1">
                      {{ stats.customersTotal }}
                      <br />
                      <v-list-item-subtitle>
                        <span class="black--text">Total Customers </span>
                        <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Number of  Customers </span>
                          </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>

            <v-col cols="6">
              <v-card
                class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1"
                color="white"
                flat
              >
                <v-list-item>
                  <v-list-item-content class="pa-2">
                    <v-list-item-title class="text-h5 mb-1">
                      {{ stats.customersBanned }}
                      <br />
                      <v-list-item-subtitle>
                        <span class="black--text">Customers Banned </span>
                        <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Number of Banned Customers </span>
                          </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>

            <v-col cols="6">
              <v-card
                class="
                  rounded-tl rounded-tr rounded-bl rounded-br
                  elevation-1
                  mt-n4
                "
                color="white"
                flat
              >
                <v-list-item>
                  <v-list-item-content class="pa-2">
                    <v-list-item-title class="text-h5 mb-1">
                      {{ stats.customersInActiveTotal }}
                      <br />
                      <v-list-item-subtitle>
                        <span class="black--text">InActive Customers </span>
                        <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Number of InActive Customers </span>
                          </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>

            <v-col cols="6">
              <v-card
                class="
                  rounded-tl rounded-tr rounded-bl rounded-br
                  elevation-1
                  mt-n4
                "
                color="white"
                flat
              >
                <v-list-item>
                  <v-list-item-content class="pa-2">
                    <v-list-item-title class="text-h5 mb-1">
                      {{ stats.customersActiveTotal }}
                      <br />
                      <v-list-item-subtitle>
                        <span class="black--text">Active Customers</span>
                        <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Number of Active Customers </span>
                          </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
      <!-- <v-col cols="6">
        <base-material-card color="primary">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">Revenue</div>
          </template>
          <vue-apex-charts
            width="100%"
            height="350"
            type="line"
            :options="requestsChart.options"
            :series="requestsChart.series"
          />
        </base-material-card>
      </v-col> -->
      <v-col cols="6" class="px-0">
        <base-material-card style="background-color: #f7f7f7">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">Drivers Stats</div>
          </template>
           <!-- <vue-apex-charts
            width="100%"
            height="350"
            type="pie"
            :options="requestsPieChart.options"
            :series="requestsPieChart.series"
          /> -->
          <v-row>
            <v-col cols="6">
              <v-card
                class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1"
                color="white"
                flat
              >
                <v-list-item>
                  <v-list-item-content class="pa-2">
                    <v-list-item-title class="text-h5 mb-1">
                      {{ stats.driversTotal }}
                      <br />
                      <v-list-item-subtitle>
                        <span class="black--text">Total Drivers</span>
                        <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Number of Drivers </span>
                          </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>

            <v-col cols="6">
              <v-card
                class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1"
                color="white"
                flat
              >
                <v-list-item>
                  <v-list-item-content class="pa-2">
                    <v-list-item-title class="text-h5 mb-1">
                      {{ stats.driversBanned }}
                      <br />
                      <v-list-item-subtitle>
                        <span class="black--text">Banned Drivers</span>
                        <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Number of banned drivers </span>
                          </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>

            <v-col cols="6">
              <v-card
                class="
                  rounded-tl rounded-tr rounded-bl rounded-br
                  elevation-1
                  mt-n4
                "
                color="white"
                flat
              >
                <v-list-item>
                  <v-list-item-content class="pa-2">
                    <v-list-item-title class="text-h5 mb-1">
                      {{ stats.driversActiveTotal }}
                      <br />
                      <v-list-item-subtitle>
                        <span class="black--text">Active Drivers </span>
                        <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Number of Active drivers</span>
                          </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>

            <v-col cols="6">
              <v-card
                class="
                  rounded-tl rounded-tr rounded-bl rounded-br
                  elevation-1
                  mt-n4
                "
                color="white"
                flat
              >
                <v-list-item>
                  <v-list-item-content class="pa-2">
                    <v-list-item-title class="text-h5 mb-1">
                      {{ stats.driversInActiveTotal }}
                      <br />
                      <v-list-item-subtitle>
                        <span class="black--text">InActive Drivers</span>
                        <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Number of InActive Drivers </span>
                          </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
     
      <v-col cols="12" md="12">
        <h3 class="text-h3 font-weight-light text--primary">Recent Bookings</h3>
        <bookings class="px-0" :hide-default-footer="true" :max-rows="10" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { http } from "@/services/http-common";
import Bookings from "@/components/bookings";
import VueApexCharts from "vue-apexcharts";
import DateFilter from "@/components/dateFilter";

export default {
  name: "DashboardDashboard",
  components: {
    Bookings,
    VueApexCharts,
    DateFilter,
  },

  data() {
    return {
      usersChart: {
        options: {
          stroke: {
            curve: "smooth",
          },
          chart: {
            id: "users-charts",
          },
          xaxis: {
            categories: [],
            type: "datetime",
          },
          yaxis: [
            {
              title: {
                text: "Users",
              },
            },
          ],
        },
        series: [],
      },
      requestsPieChart: {
        options: {
          chart: {
            width: 380,
            type: "pie",
          },
          labels: [
            // "Total Requests",
            "Completed ",
            "Cancelled ",
            "Expired ",
            "Approved ",
            "Declined ",
            "Pending ",
          ],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
        series: [],
      },
      stats: null,
      dateFilter: null,
      initDate: [],
      chartStats: null,
      requestsChart: {
        options: {
          stroke: {
            curve: "smooth",
          },
          chart: {
            id: "users-charts",
          },
          xaxis: {
            categories: [],
            type: "datetime",
          },
          yaxis: [
            {
              title: {
                text: "Revenue",
              },
            },
          ],
        },
        series: [],
      },
      lineChart: false,
      lineChartPoints: [],
    };
  },
  computed: {
    // ...mapGetters(['stats']),
  },
  mounted() {
    window.onunload = function () {
      localStorage.removeItem('filterDate');
    }
    // const date = new Date();
    // if (!localStorage.getItem("filterDate")) {
    //   this.initDate.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    //   this.initDate.push(
    //     `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    //   );
    // } else {
    //   let datearr = localStorage.getItem("filterDate").split(",");
    //   let date1 = new Date(datearr[0]);
    //   let date2 = new Date(datearr[1]);
    //   this.initDate.push(
    //     `${date1.getFullYear()}-${date1.getMonth() + 1}-${date1.getDate()}`
    //   );
    //   this.initDate.push(
    //     `${date2.getFullYear()}-${date2.getMonth() + 1}-${date2.getDate()}`
    //   );
    // }
    this.loadStats()
    this.loadChartStats()
  },
  methods: {
    complete(index) {
      this.list[index] = !this.list[index];
    },
    toggleChart(){
      this.lineChart = !this.lineChart
      this.lineChartPoints=[]
      this.loadChartStats()
    },
    toFixedTrunc(x, n) {
      const v = (typeof x === "string" ? x : x.toString()).split(".");
      if (n <= 0) return v[0];
      let f = v[1] || "";
      if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
      while (f.length < n) f += "0";
      return `${v[0]}.${f}`;
    },
    onDateChange(newValue) {
      if (newValue.length < 2) return;
      localStorage.setItem("filterDate", newValue);
      let finaldate = localStorage.getItem("filterDate").split(",");
      this.dateFilter = localStorage.getItem("filterDate")
        ? [new Date(finaldate[0]), new Date(finaldate[1])]
        : newValue;
      this.loadStats();
      this.loadChartStats();
    },
    loadStats() {
      console.log(
        `${process.env.VUE_APP_API}/api/admin/dashboardData/getDashboardData`+(this.dateFilter ? `?start=${this.dateFilter[0]}&end=${this.dateFilter[1]}`: '')
      );
      http
        .get(
          `${process.env.VUE_APP_API}/api/admin/dashboardData/getDashboardData`+(this.dateFilter ? `?start=${this.dateFilter[0]}&end=${this.dateFilter[1]}`: '') )
        .then((res) => {
          this.stats = res.data;
          console.log(res);
        });
    },
    loadChartStats() {
      console.log(
        `${process.env.VUE_APP_API}/api/admin/stats`+(this.dateFilter ? `?start=${this.dateFilter[0]}&end=${this.dateFilter[1]}`: '')
      );
      http
        .get(
          `${process.env.VUE_APP_API}/api/admin/stats`+(this.dateFilter ? `?start=${this.dateFilter[0]}&end=${this.dateFilter[1]}`: '')
        )
        .then((res) => {
          this.chartStats = res.data;
          
          // this.barGraphData = res.data.graphData
          // // this.constructBarGraphData()
          this.usersChart.options.xaxis.categories = res.data.days;
          const usersDataPoints = [];
          const requestsDataPoints = [];
          // const lastNumberOfCustomer =
          //   this.stats.users_count.total_customers -
          //   this.stats.users_count.new_customers_count;
          // const lastNumberOfProfessional =
          //   this.stats.users_count.total_professional -
          //   this.stats.users_count.new_professionals_count;
          // const lastTotalRevenue =
          //   this.stats.career_path_account_info.total_revenue -
          //   this.stats.career_path_account_info.total_revenue_new;
          // const lastTotalDiscount =
          //   this.stats.career_path_account_info.total_discount -
          //   this.stats.career_path_account_info.total_discount_new;
          // const lastTotalUnprocessed =
          //   this.stats.career_path_account_info.total_unprocessed -
          //   this.stats.career_path_account_info.total_unprocessed_new;
          // const lastTotalCommisson =
          //   this.stats.career_path_account_info.total_commisson -
          //   this.stats.career_path_account_info.total_commission_new;
          // const lastTotalProfessionalCommisson =
          //   this.stats.career_path_account_info.total_professional_commission -
          //   this.stats.career_path_account_info.total_professional_commission_new;
          // const lastTotalPayout =
          //   this.stats.career_path_account_info.total_payouts -
          //   this.stats.career_path_account_info.total_payouts_new;
          // const lastTotalRefund =
          //   this.stats.career_path_account_info.total_refund -
          //   this.stats.career_path_account_info.total_refund_new;

          this.chartStats.payments_info.group_by_data.forEach((elm) => {
            if (elm.name === "Discounts") {
              let dataArray = [];
              let total = 0;
              let totalLineChart =0
              let dataArrayLineChart = []
              elm.count.map((row) => {
                total = total + parseFloat(row);
                totalLineChart= parseFloat(row);
                dataArrayLineChart.push(totalLineChart.toFixed(2))
                dataArray.push(total.toFixed(2));
              });
              requestsDataPoints.push({ name: elm.name, data: dataArray });
              // this.lineChartPoints.push({ name: elm.name, data: dataArrayLineChart });
            } else if (elm.name === "Revenue") {
              let dataArray = [];
              let total = 0;
              let totalLineChart =0
              let dataArrayLineChart = []
              elm.count.map((row) => {
                total = total + parseFloat(row);
                totalLineChart= parseFloat(row);
                dataArrayLineChart.push(totalLineChart.toFixed(2))

                dataArray.push(total.toFixed(2));
              });
              requestsDataPoints.push({ name: elm.name, data: dataArray });
              this.lineChartPoints.push({ name: elm.name, data: dataArrayLineChart });
            } else if (elm.name === "Winchit Commission") {
              let dataArray = [];
              let total = 0;
              let totalLineChart =0
              let dataArrayLineChart = []
              elm.count.map((row) => {
                total = total + parseFloat(row);
                totalLineChart= parseFloat(row);
                dataArrayLineChart.push(totalLineChart.toFixed(2))

                dataArray.push(total.toFixed(2));
              });
              requestsDataPoints.push({ name: elm.name, data: dataArray });
              this.lineChartPoints.push({ name: elm.name, data: dataArrayLineChart });
            } else if (elm.name === "Driver Commission") {
              let dataArray = [];
              let total = 0;
              let totalLineChart =0
              let dataArrayLineChart = []
              elm.count.map((row) => {
                total = total + parseFloat(row);
                totalLineChart= parseFloat(row);
                dataArrayLineChart.push(totalLineChart.toFixed(2))

                dataArray.push(total.toFixed(2));
              });
              requestsDataPoints.push({ name: elm.name, data: dataArray });
              this.lineChartPoints.push({ name: elm.name, data: dataArrayLineChart });
            } else if (elm.name === "Total Payouts") {
              let dataArray = [];
              let total = 0;
              let totalLineChart =0
              let dataArrayLineChart = []
              elm.count.map((row) => {
                total = total + parseFloat(row);
                totalLineChart= parseFloat(row);
                dataArrayLineChart.push(totalLineChart.toFixed(2))
                dataArray.push(total.toFixed(2));
              });
              requestsDataPoints.push({ name: elm.name, data: dataArray });
              this.lineChartPoints.push({ name: elm.name, data: dataArrayLineChart });
            } else if (elm.name === "Cancel Fee") {
              let dataArray = [];
              let total = 0;
              let totalLineChart =0
              let dataArrayLineChart = []
              elm.count.map((row) => {
                total = total + parseFloat(row);
                totalLineChart= parseFloat(row);
                dataArrayLineChart.push(totalLineChart.toFixed(2))
                dataArray.push(total.toFixed(2));
              });
              requestsDataPoints.push({ name: elm.name, data: dataArray });
              this.lineChartPoints.push({ name: elm.name, data: dataArrayLineChart });
            } 
          });
          const bookingsSeriesPieCart = [
            // this.stats.bookings_count.total_requests_count,
            this.chartStats.bookingCounts.completedBookingCount,
            this.chartStats.bookingCounts.cancelledBookingCount,
            this.chartStats.bookingCounts.expiredBookingCount,
            this.chartStats.bookingCounts.scheduledBookingCount,
            this.chartStats.bookingCounts.activeBookingCount,
          ];
          console.log("pie", bookingsSeriesPieCart);
          this.chartStats.user_counts.group_by_data.forEach((elm) => {
            if (elm.name === "Customers") {
              let newArr = [];
              let total = 0;
              elm.count.map((row) => {
                total += row;
                newArr.push(total);
              });
              usersDataPoints.push({ name: elm.name, data: newArr });
            } else {
              let newArr = [];
              let total = 0;
              elm.count.map((row) => {
                total += row;
                newArr.push(total);
              });
              usersDataPoints.push({ name: elm.name, data: newArr });
            }
          });
          this.usersChart.series = usersDataPoints;
          this.usersChart = {
            options: {
              stroke: {
                curve: "smooth",
              },
              chart: {
                id: "users-charts",
              },
              xaxis: {
                categories: res.data.days,
                type: "datetime",
              },
              yaxis: [
                {
                  title: {
                    text: "Users",
                  },
                },
              ],
              tooltip: {
    custom: function({ series, seriesIndex, dataPointIndex, w }) {
      console.log(series,"series")
      console.log(seriesIndex,"seriesIndex")
      console.log(dataPointIndex,"dataPointIndex")
      console.log( w.globals.seriesNames,"w")

      return (
        `
  <div class="container">
  <div class="items">
    <div class="items-head">
    </div>
    <div class="items-body">
      <div class="items-body-content">
        <li><span>${w.globals.seriesNames[0]} : ${series[0][dataPointIndex]}</span></li> 
      </div>
      <div class="items-body-content">
        <li><span>${w.globals.seriesNames[1]} : ${series[1][dataPointIndex]}</span></li>
      </div>
      <div class="items-body-content">
        <li><span>Ratio : ${(series[0][dataPointIndex] / series[1][dataPointIndex]).toFixed(2)}</span></li>
      </div>
    </div>
  </div>
</div>
` 
        
      );
    }
  }
            },
            series: usersDataPoints,
            
          };

          this.requestsChart = {
            options: {
              colors:['#2d6a4f', '#73777B', '#eeef20', '#990099',  '#5800FF', '#ff8fa3', '#171717', '#EB1D36'],
              stroke: {
                curve: "smooth",
              },
              chart: {
                id: "requests-charts",
                labels: {
                  formatter: function (value) {
                    return value + "£";
                  },
                },
              },
              xaxis: {
                categories: res.data.days,
                type: "datetime",
              },
              yaxis: [
                {
                  title: {
                    text: "£",
                    style: {
                      fontSize: '18px',
                      fontWeight: 'bold',
                    }
                  },
                },
              ],
            },
            series: this.lineChart? this.lineChartPoints : requestsDataPoints,
            tickAmount: 6,
          };
          this.requestsPieChart = {
            options: {
              chart: {
                width: 380,
                type: "pie",
              },
              colors:['#BA68C8', '#F06292', '#64B5F6','#81C784', '#FFD54F', '#FF8A65'],
              labels: [
                // "Total Requests",
                "Completed ",
                "Cancelled ",
                "Expired ",
                "Scheduled ",
                "Pending ",
              ],
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 200,
                    },
                    legend: {
                      position: "bottom",
                    },
                  },
                },
              ],
            },
            series: bookingsSeriesPieCart,
          };
        });
    },
  },
};
</script>
